import { useEffect, lazy, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch, withRouter, Redirect, useLocation } from 'react-router-dom';
import { lazyWithRetry } from './components/lazyWithRetry';
import './App.css';
import './assets/scss/main.scss';
import { actionCreator } from './store/index';
import { socket, joinUserToRoom } from './services/socket.service';
import { isSuperadminDomain, getUserItem, showToaster } from './services/helper';
import ErrorLogReportModal from './services/ErrorLogReportModal';

const AdminRoute = lazyWithRetry(() => import('./lib/AdminRoute'));
const ClientRoute = lazyWithRetry(() => import('./lib/ClientRoute'));
const ClientRoutes = lazyWithRetry(() => import('./routes/client.route'));
const PrivateRoute = lazyWithRetry(() => import('../src/lib/PrivateRoute'));
const SuperAdminRoutes = lazyWithRetry(() => import('./routes/superadmin.route'));

const ThankYou = lazyWithRetry(() => import('./modules/ThankYou'));
const Authorize = lazyWithRetry(() => import('./services/Authorize'));
const AdminLogin = lazyWithRetry(() => import('./modules/login/adminLogin'));
const Subscriptions = lazyWithRetry(() => import('./modules/subscriptions'));
const Profile = lazyWithRetry(() => import('./modules/auth/profile/Profile'));

const PageNotFound = lazyWithRetry(() => import('./modules/projects/404page'));
const ClientLogin = lazyWithRetry(() => import('./modules/login/clientLogin'));
const NewRegister = lazyWithRetry(() => import('./modules/login/NewRegister'));
const AdminNoAuthRoute = lazyWithRetry(() => import('./lib/AdminNoAuthRoute'));
const NotFound = lazyWithRetry(() => import('./modules/auth/notfound/NotFound'));

const GenericGlobalComponent = lazyWithRetry(() => import('./components/generic'));
const VisualAssets = lazyWithRetry(() => import('./modules/dashboard/VisualAssets'));
const OrgCreation = lazyWithRetry(() => import('./modules/container/user-plan/orgCreation'));
const PlanDetailsPage = lazyWithRetry(() => import('./modules/subscriptions/PlanDetailsPage'));
const RollAssignTable = lazyWithRetry(() => import('./modules/container/role-table/roleAssign'));

const DownloadVideo = lazyWithRetry(() => import('./modules/container/renderList/DownloadModal'));
const ResetPassword = lazyWithRetry(() => import('./modules/auth/reset-password/reset_password'));
const UsageTracking = lazyWithRetry(() => import('./modules/container/projectlist/UsageTracking'));
const projectRenderList = lazyWithRetry(() => import('./modules/container/renderList/projectlist'));

const ForgotPassword = lazyWithRetry(() => import('./modules/auth/forgot-password/forgot_password'));
const CreateProject = lazyWithRetry(() => import('./modules/container/create-project/create_project'));
const RenderProjectList = lazyWithRetry(() => import('./modules/container/projectlist/Renderprojectlist'));
const DownloadProjectlistVideo = lazyWithRetry(() => import('./modules/container/projectlist/DownloadModal'));

const VisualAssetsMultiple = lazyWithRetry(() => import('./modules/dashboard/VisualAssets-UploadMultiFiles'));
const RenderPaymentMethods = lazyWithRetry(() => import('./modules/container/user-plan/renderPaymentMethod'));
const UsageTrackingDetails = lazyWithRetry(() => import('./modules/container/renderList/RenderTrackingDetails'));
const UsageTrackingDetailsParent = lazyWithRetry(() => import('./modules/container/projectlist/RenderTrackingDetails'));
function App() {
  const dispatch = useDispatch();
  const { UpdateProjectScreenPreview } = bindActionCreators(actionCreator, dispatch);
  const location = useLocation();

  useEffect(() => {
    socket.on('connect', () => {
      joinUserToRoom();
    });
    socket.on('ScreenPreview', data => {
      try {
        const userId = getUserItem('id');
        if (userId === data.userId) {
          UpdateProjectScreenPreview(data);
        }
      } catch (error) {
        console.log({ sockerScreenPreviewError: error });
      }
    });
    socket.on('TrimVideoData', data => {
      try {
        const userId = getUserItem('id');
        if (userId === data.userId) {
          if (data?.data?.error) {
            return showToaster(`Your video ${data.data.fileName} could not be uploaded. ${data.data.data}`, 'error', 5000);
          }

          showToaster(`Your video ${data.data.fileName} has been uploaded and is ready to use.`, 'success', 5000);
        }
      } catch (error) {
        console.log({ sockerScreenPreviewError: error });
      }
    });
    return () => {
      socket.off('connect');
      socket.off('ScreenPreview');
    };
  }, []);

  const isSuperAdmin = isSuperadminDomain;

  function newScript(src) {
    // create a promise for the newScript
    return new Promise(
      function (resolve, reject) {
        // create an html script element
        var script = document.createElement('script');
        // set the source of the script element
        script.src = src;
        // set a listener when the script element finishes loading the script
        script.addEventListener(
          'load',
          function () {
            // resolve if the script element loads
            resolve();
          }.bind(this)
        );
        // set a listener when the script element faces any errors while loading
        script.addEventListener(
          'error',
          function (e) {
            // reject if the script element has an error while loading the script
            reject(e);
          }.bind(this)
        );
        // append the script element to the body
        document.body.appendChild(script);
      }.bind(this)
    );
  }

  const identifyUser = () => {
    const userId = getUserItem('id');
    if (userId) {
      if (window.Appcues !== undefined && window.Appcues !== null) {
        window.Appcues.identify(
          userId, // unique, required
          {
            role: getUserItem('role') || 'SuperAdmin', // Current user’s role or permissions
            accountId: getUserItem('clientId'), // Current user's account ID
            firstName: getUserItem('firstName'), // current user's first name
          }
        );
      } else {
        newScript("//fast.appcues.com/211414.js'")
          .then(
            function () {
              // then recursively call identifyUser to initialize the identity of the user
              identifyUser();
              // catch any error and print to the console
            }.bind(this)
          )
          .catch(function (error) {
            console.log('identifyUser: error on loading script');
          });
      }
    }
  };

  useEffect(() => {
    identifyUser();
  }, [location.pathname]);

  return (
    <>
      <ErrorLogReportModal />
      <Suspense fallback={<div></div>}>
        <GenericGlobalComponent />
      </Suspense>

      <Suspense fallback={<div></div>}>
        <Switch>
          <Route path="/:planId/:interval/thank-you" exact component={ThankYou} />
          <Route path="/reset" exact component={ResetPassword} />
          <Route path="/forgot" exact component={ForgotPassword} />

          <Route exact path="/plans">
            {isSuperAdmin ? (
              <Suspense fallback={<div></div>}>
                {' '}
                <AdminNoAuthRoute path="/plans" component={Subscriptions} />
              </Suspense>
            ) : (
              <Suspense fallback={<div></div>}>
                <ClientRoute path="/plans" component={Subscriptions} />
              </Suspense>
            )}
          </Route>
          <Route exact path="/plan/:id">
            {isSuperAdmin ? (
              <Suspense fallback={<div></div>}>
                <PlanDetailsPage />
              </Suspense>
            ) : (
              <ClientRoute path="/plan/:id" component={PlanDetailsPage} />
            )}
          </Route>
          <Route exact path="/:planId/register">
            {isSuperAdmin ? (
              <Suspense fallback={<div></div>}>
                <NewRegister />
              </Suspense>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route exact path="/login">
            {isSuperAdmin ? (
              <Suspense fallback={<div></div>}>
                <AdminLogin />
              </Suspense>
            ) : (
              <Suspense fallback={<div></div>}>
                <ClientLogin />
              </Suspense>
            )}
          </Route>

          <Route path="/page-not-found" exact component={PageNotFound} />
          <PrivateRoute path="/edit-profile" exact component={Profile} />
          <PrivateRoute path="/download" exact component={DownloadVideo} />
          <Route path="/create-organization" exact component={OrgCreation} />
          <PrivateRoute path="/rollassign" exact component={RollAssignTable} />
          <PrivateRoute path="/usage-tracking" exact component={UsageTracking} />
          <PrivateRoute path="/create-project" exact component={CreateProject} />
          <PrivateRoute path="/render-list" exact component={projectRenderList} />
          <Route path="/authorize/:id/:projectName" exact component={Authorize} />
          <PrivateRoute path="/repository-management" exact component={VisualAssets} />
          <PrivateRoute path="/render-list/download" exact component={DownloadVideo} />
          <PrivateRoute path="/render-details" exact component={UsageTrackingDetails} />
          <PrivateRoute path="/project-render-list" exact component={RenderProjectList} />
          <PrivateRoute path="/project-list/download" exact component={DownloadProjectlistVideo} />
          <PrivateRoute path="/render-project-details" exact component={UsageTrackingDetailsParent} />
          <PrivateRoute path="/payment-methods/:id" exact component={RenderPaymentMethods} />
          <PrivateRoute exact component={VisualAssetsMultiple} path="/repository-management-upload-multiple-files" />

          <Route exact path="/">
            {isSuperAdmin ? <Redirect to="/admin" /> : <ClientRoute path="/" component={ClientRoutes} />}
          </Route>
          {/* NOTE: ALWAYS, KEEPS ADMIN_ROUTE ABOVE CLIENT_ROUTE */}
          <AdminRoute path="/admin" component={SuperAdminRoutes} />
          <ClientRoute path="/" component={ClientRoutes} />

          {/* NOTE: DO NOT ADD ANY ROUTE BELOW NOT_FOUND ROUTE  */}
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </>
  );
}

export default withRouter(App);
